<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  :host {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
    font-size: 16px;
  }

  .content {
    display: flex;
    margin: 82px auto 32px;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
    
  }

  @media(max-width: 545px){
    .content{
      align-items: inherit; 
    } 
  }

  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }

</style>

<app-loader *ngIf="loader"></app-loader>
<app-errore-carta *ngIf="erroreCarta" (closeModal)="closeModal()"></app-errore-carta>
<img class="logo" src="../web/app/assets/AC_logo_72dpi.png">
<div class="content" role="main" [class.inherit]="step === 2">
  <app-homepage *ngIf="step === 1" [data]="data" (nextStep)="nextStep()"></app-homepage>
  <app-cdc *ngIf="step === 2" (submitCdcForm)="submitCdcForm($event)"></app-cdc>
  <app-wait *ngIf="step === 4" [error]="error" [success]="success"></app-wait>
  <app-final-step *ngIf="step === 3" [error]="error" [success]="success"></app-final-step>
  <router-outlet></router-outlet>
</div>
