<div class="home-container">
  <p class="label">Nome e Cognome</p>
  <div class="input">
    {{name}}
  </div>
  <p class="label">Codice Fiscale</p>
  <div class="input">
    {{codFisc}}
  </div>
  <p class="label">Numero Contratto</p>
  <div class="input">
    {{numContratto}}
  </div>
  <div class="button" (click)="next()">
    <img class="arrow-icon" src="./../web/app/assets/arrow-right-white.svg"/>
    <span>Prosegui</span>
  </div>
</div>
