import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-final-step',
  templateUrl: './final-step.component.html',
  styleUrls: ['./final-step.component.scss']
})
export class FinalStepComponent implements OnInit {
  @Input() error = false;
  @Input() success = false;
  subtitle = 'Il link è scaduto';
  errorSub = "ci scusiamo per il disagio ma al momento il servizio non è disponibile per problemi tecnici."
  succesSub = "l'inserimento dei tuoi dati è andato a buon fine.";
  text = 'Riceverai un nuovo link per inserire i tuoi dati di pagamento. Se il link è scaduto per tre volte, contattaci al numero 800591803 per ricevere supporto. Cordiali Saluti.'
  textSuccess = "Restiamo a tua disposizione per ogni ulteriore chiarimento al numero 800 591 803, cogliamo l'occasione per porgerti i nostri più cordiali saluti."
  constructor() { }

  ngOnInit(): void {
  }

}
