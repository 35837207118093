import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegexService } from '../services/regex.service';
import * as moment from 'moment';
import {environment} from "../../environments/environment";


@Component({
  selector: 'app-cdc',
  templateUrl: './cdc.component.html',
  styleUrls: ['./cdc.component.scss']
})
export class CdcComponent implements OnInit {
  @Output() submitCdcForm = new EventEmitter();
  paymentForm: any;
  isExpiryDateInvalid: boolean = false;
  selectedYear: any;
  selectedMonth: any;
  today = new Date();
  showMonth = false;
  showYear = false;
  resolvedCaptcha = false;
  MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  YEARS = ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032'];
  errorMessages = {
    nome: [
      {
        type: 'required',
        message: 'Il nome è obbligatorio',
      },
      {
        type: 'pattern',
        message:
          'Non sono ammessi numeri, caratteri speciali e lettere accentate. In caso di lettere accentate usare il carattere apostrofo.',
      },
    ],
    numeroCarta: [
      { type: 'required', message: 'Il numero carta è obbligatorio' },
      {
        type: 'pattern',
        message: 'Numero carta di credito non valido',
      },
    ],
    codiceDiSicurezza: [
      { type: 'required', message: 'Il codice di sicurezza è obbligatorio' },
      {
        type: 'minLength',
        message: 'Deve contenere 3 o 4 cifre.',
      },
      {
        type: 'pattern',
        message: 'Deve contenere 3 o 4 cifre.',
      },
    ],
  };
  constructor(
    private formBuilder: FormBuilder,
    private regex: RegexService
  ) { }

  get nome() {
    return this.paymentForm.get('nome');
  }

  get numeroCarta() {
    return this.paymentForm.get('numeroCarta');
  }

  get annoDiScadenza() {
    return this.paymentForm.get('annoDiScadenza');
  }

  get meseDiScadenza() {
    return this.paymentForm.get('meseDiScadenza');
  }
  get codiceDiSicurezza() {
    return this.paymentForm.get('codiceDiSicurezza');
  }

  ngOnInit(): void {
    this.paymentForm = this.formBuilder.group({
      nome: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.regex.regExNome),
        ])
      ),
      numeroCarta: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.regex.regExCc),
        ])
      ),
      codiceDiSicurezza: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.pattern('[0-9]{3,4}')])
      ),

      meseDiScadenza: new FormControl('', Validators.compose([Validators.required])),
      annoDiScadenza: new FormControl('', Validators.compose([Validators.required])),
      reCaptcha: new FormControl(),
    });
  }

  onSubmitCdcForm() {
    this.submitCdcForm.emit(this.paymentForm);
  }

  expiryDateValidator() {
    const monthDate = this.paymentForm.get('meseDiScadenza').value;
    const yearDate = this.paymentForm.get('annoDiScadenza').value;
    if (monthDate && yearDate) {
      this.selectedMonth = moment(monthDate).format('MM');
      this.selectedYear = moment(yearDate).format('YYYY');
      const actualYear = this.today.getFullYear();
      const actualMonth = this.today.getMonth() + 1;
      if (
        parseInt(this.selectedYear) == actualYear &&
        parseInt(this.selectedMonth) < actualMonth
      ) {
        return (this.isExpiryDateInvalid = true);
      } else {
        return (this.isExpiryDateInvalid = false);
      }
    }
  }

  changeShowMonth() {
    this.showMonth = !this.showMonth;
  }

  changeShowYear() {
    this.showYear = !this.showYear;
  }

  selectYear(year: any) {
    this.paymentForm.controls['annoDiScadenza'].setValue(year);
    this.changeShowYear();
    this.expiryDateValidator();
  }

  selectMonth(month: any) {
    this.paymentForm.controls['meseDiScadenza'].setValue(month);
    this.changeShowMonth();
    this.expiryDateValidator();
  }

  openCdcTermsPdf() {
    const url = `${environment.eeDomain}/wps/wcm/myconnect/dc28214e-8fc8-4c0d-9c32-404bb1c022aa/Condizioni+addebito+carta+di+credito_RES.PDF?MOD=AJPERES`;
    window.open(url, "_blank");
  }

  resolved(event) {
    if (event) {
      this.resolvedCaptcha = true;
    } else {
      this.resolvedCaptcha = false;
    }
  }

  errored(event) {
    this.resolvedCaptcha = false;
  }
}
