import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  constructor() {}

  @Output() nextStep: EventEmitter<any> = new EventEmitter<any>();
  @Input() data: any;
  name = ""
  codFisc = ""
  numContratto = ""

  ngOnInit() {
    console.log('HomePage component:', this.data);
    this.name = this.data.nome + ' ' + this.data.cognome
    this.codFisc = this.data.codiceFiscale
    this.numContratto = this.data.contractAccount
  }

  next(){
    this.nextStep.emit()
  }
}
