<div class="final-container">
    <img *ngIf="!success" class="img" src="../../web/app/assets/sad.png"/>
    <p class="orange title bold">Gentile cliente,</p>
    <img *ngIf="success" src="../../web/app/assets/auth-success-icon.svg"/>
    <p *ngIf="!error && !success" class="bold">{{subtitle}}</p>
    <p *ngIf="error && !success" class="bold">{{errorSub}}</p>
    <p *ngIf="!error && success" class="bold">{{succesSub}}</p>
    <p *ngIf="!error && !success" class="text-center">{{text}}</p>
    <p *ngIf="success || error" class="text-center">{{textSuccess}}</p>
    <h4 class="orange">ASSISTENZA CASA</h4>
</div>