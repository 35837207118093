import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CdcComponent } from './cdc/cdc.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FinalStepComponent } from './final-step/final-step.component';
import { LoaderComponent } from './loader/loader.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ErroreCartaComponent } from './errore-carta/errore-carta.component';
import { WaitComponent } from './wait/wait.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    CdcComponent,
    FinalStepComponent,
    LoaderComponent,
    ErroreCartaComponent,
    WaitComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
