<div class="cdc-container">
  <div class="row">
    I dati dell'intestatario della Carta di Credito devono coincidere con i dati
    da te inseriti in fase di sottoscrizione.
  </div>
  <div class="row start flex">
    Sicurezza By Banca Sella
    <div class="info">
      i
      <span class="tooltiptext"
        >I dati della tua carta di credito sono memorizzati direttamente sui
        server bancari di Banca Sella. Per la tua sicurezza, Edison Energia non
        ha accesso ai dati e non li memorizza in alcun modo</span
      >
    </div>
  </div>
  <div class="row start grey">
    AUTORIZZAZIONE PERMANENTE DI ADDEBITO CONTINUATIVO SU CARTA DI CREDITO
    <br />
    Con l'inserimento dei dati, confermi di aver letto e accettato tutte le
    clausole contrattuali riportate all'interno del Modulo per richiesta
    addebito su Carta di Credito.
  </div>
  <div class="row start">
    Sono ammessi pagamenti con le seguenti carte di credito:
  </div>
  <img class="imgCdc" src="../../web/app/assets/metodi-di-pagamento.png" />
  <form [formGroup]="paymentForm" novalidate class="cdc-form">
    <div class="row start">Intestatario carta di credito</div>
    <input
      formControlName="nome"
      type="text"
      autocomplete="cc-name"
      required
      class="input"
      type="text"
    />

    <div class="error-container">
      <ng-container *ngFor="let error of errorMessages.nome">
        <p
          class="error-message"
          *ngIf="nome.hasError(error.type) && (nome.dirty || nome.touched)"
        >
          {{ error.message }}
        </p>
      </ng-container>
    </div>

    <div class="row start">Numero carta di credito</div>
    <input
      class="input"
      type="text"
      maxlength="19"
      formControlName="numeroCarta"
      type="text"
      autocomplete="cc-number"
      required
    />

    <div class="error-container">
      <ng-container *ngFor="let error of errorMessages.numeroCarta">
        <p
          class="error-message"
          *ngIf="
            numeroCarta.hasError(error.type) &&
            (numeroCarta.dirty || numeroCarta.touched)
          "
        >
          {{ error.message }}
        </p>
      </ng-container>
    </div>

    <div class="row column justify">
      <div class="box">
        Scadenza
        <div (click)="changeShowMonth()" class="input select">
          {{ paymentForm.value.meseDiScadenza || "MESE" }}
          <img src="../../web/app/assets/arrow-down-icon.svg" />
        </div>
        <div class="list-container" *ngIf="showMonth">
          <div
            (click)="selectMonth(month)"
            class="elem"
            *ngFor="let month of MONTHS"
          >
            {{ month }}
          </div>
        </div>
      </div>
      <div class="box">
        <div (click)="changeShowYear()" class="input select">
          {{ paymentForm.value.annoDiScadenza || "ANNO" }}
          <img src="../../web/app/assets/arrow-down-icon.svg" />
        </div>
        <div class="list-container" *ngIf="showYear">
          <div
            (click)="selectYear(year)"
            class="elem"
            *ngFor="let year of YEARS"
          >
            {{ year }}
          </div>
        </div>
      </div>
      <div class="box">
        CVV2
        <input
          class="input mrg5 lessHeight"
          type="number"
          maxlength="3"
          formControlName="codiceDiSicurezza"
          autocomplete="cc-number"
          required
        />
      </div>
    </div>
    <div class="error-container">
      <p class="error-message" *ngIf="isExpiryDateInvalid">
        La data selezionata non è valida.
      </p>
      <ng-container *ngFor="let error of errorMessages.codiceDiSicurezza">
        <p
          class="error-message"
          *ngIf="
            codiceDiSicurezza.hasError(error.type) &&
            (codiceDiSicurezza.dirty || codiceDiSicurezza.touched)
          "
        >
          {{ error.message }}
        </p>
      </ng-container>
    </div>

    <div class="row">Non verrà addebbitato nessun importo.</div>
    <div class="row flex pointer">
      <img class="pdf" src="../../web/app/assets/pdf.svg" />
      <p class="informativa-label" (click)="openCdcTermsPdf()">
        Condizioni di addebito su carta di credito
      </p>
    </div>
    <div class="reCaptcha-container">
      <re-captcha
        (resolved)="resolved($event)"
        (error)="errored($event)"
        errorMode="handled"
        siteKey="6LdseYgiAAAAAHASoRpCowy4ao9lvent_GMFvQOD"
      ></re-captcha>
    </div>
    <div class="row">
      <input
        class="button"
        type="button"
        value="Conferma"
        [ngClass]="{
          disabled: this.paymentForm.status === 'INVALID' || !resolvedCaptcha
        }"
        (click)="onSubmitCdcForm()"
        [disabled]="this.paymentForm.status === 'INVALID' || !resolvedCaptcha"
      />
    </div>
  </form>
</div>
