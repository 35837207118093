import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { LoggerService } from './services/logger.service';
import { NetworkServiceService } from './services/network-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  token;
  step = 0;
  error = false;
  data: any;
  shopLogin: any;
  cdcFormData: any;
  TransKey: any;
  VBVRisp: any;
  PaRes: string;
  success = false;
  loader = false;
  erroreCarta = false;
  pathSecurePay = environment.pathSecurePay;
  eeDomain = environment.eeDomain;
  acDomain = environment.acDomain;
  wait: any;
  constructor(
    private route: ActivatedRoute,
    private networkService: NetworkServiceService,
    private loggerService: LoggerService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      console.log('params', params);
      this.token = params.token;
      this.PaRes = params.PaRes;
      if (this.token) {
        this.loader = true;
        this.networkService.recuperoDatiCdCAltriCanali(this.token).subscribe(
          (data: any) => {
            this.shopLogin = data.shopLogin;
            this.loader = false;
            this.data = data;
            window.localStorage.setItem('codiceCliente', this.data.codiceCliente);
            window.localStorage.setItem('token', this.token);
            if (!this.data.valido) {
              this.step = 3;
              if (this.data.descrizioneStato === "Token scaduto") {
                this.success = false;
                this.error = false;
              }
            } else {
              this.step = 1;
            }
          },
          (error) => {
            this.loader = false;
            this.step = 3;
            this.success= false;
            this.error = true;
          }
        );
      } else if (this.PaRes) {
        this.step = 4;
        this.verifyPathPares();
      } else {
        this.step = 3;
        this.success= false;
        this.error = true;
      }
    });
  }

  nextStep() {
    this.step++;
  }

  addebitoDirettoCdcConferma() {
    this.loader = true;
    this.networkService
      .getToken(
        this.data.codiceCliente,
        this.data.contractAccount,
        this.data.segmento,
        this.shopLogin
      )
      .subscribe(
        (tokenResponse) => {
          this.loggerService.postLogInfo(
            'getTokenRicorrente - RESPONSE',
            tokenResponse
          );
          this.onGetTokenRicorrenteSuccess(tokenResponse);
        },
        (error) => {
          this.loader = false;
          this.loggerService.postLogInfo('getTokenRicorrente - ERROR', error);
          this.step = 3;
          this.success= false;
          this.error = true;
        }
      );
  }

  async onGetTokenRicorrenteSuccess(response) {
    let serviceToLog = 'createPaymentPage';
    try {
      this.token = response['token'];
      localStorage.setItem('cdcShopLogin', this.shopLogin);
      localStorage.setItem('cdcToken', this.token);
      const data = await this.networkService.createPaymentPage(
        this.shopLogin,
        this.token
      );
      this.loggerService.postLogInfo('createPaymentPage - RESPONSE', data);
      serviceToLog = 'sendPayment';
      await this.sendPayment();
    } catch (e) {
      this.loader = false;
      this.loggerService.postLogInfo(`${serviceToLog} - ERROR`, e);
      this.step = 3;
      this.success= false;
      this.error = true;
    } finally {
      this.loader = false;
    }
  }

  sendPayment() {
    const EXPYY = moment(this.cdcFormData.value.annoDiScadenza).format('YYYY');
    const cardData = {
      Name: this.cdcFormData.value.nome.toUpperCase(),
      CC: this.cdcFormData.value.numeroCarta,
      EXPYY: EXPYY.toString().substr(-2),
      EXPMM: moment(this.cdcFormData.value.meseDiScadenza).format('MM'),
      CVV2: this.cdcFormData.value.codiceDiSicurezza,
    };
    this.networkService.sendPayment(cardData).then(
      (data) => {
        this.loggerService.postLogInfo('sendPayment - RESPONSE', data);
        this.onSendPaymentSuccess(data);
      },
      (error) => {
        this.loader = false;
        this.loggerService.postLogInfo('sendPayment - ERROR', error);
        this.step = 3;
        this.success= false;
        this.error = true;
      }
    );
  }

  async onSendPaymentSuccess(response: any) {
    try {
      let successToken: any;
      if (response.threeDS) {
        this.loader = false;
        window.localStorage.setItem(
          'PAYMENT_TYPE',
          'ADDEBITO - CARTA DI CREDITO 3DS'
        );
        this.TransKey = response.TransKey;
        this.VBVRisp = response.VBVRisp;
        localStorage.setItem('cdcTransKey', this.TransKey);
        this.onThreeDSOpenBrowser();
        return;
      } else {
        successToken = response.EncryptedString;
        this.loader = true;
        const resOutcome = await this.networkService
          .getOutcome(successToken, this.shopLogin)
          .toPromise();
        this.loggerService.postLogInfo('getOutcome - RESPONSE', resOutcome);
        await this.onGetOutcomeSuccess(resOutcome, successToken);
      }
    } catch (e) {
      this.loggerService.postLogInfo('getOutcome - ERROR', e);
      this.step = 3;
      this.error = true;
      this.loader = false;
    } finally {
      this.loader = false;
    }
  }

  onThreeDSOpenBrowser() {
    var SecureForm = document.createElement('form');
    SecureForm.action = this.pathSecurePay;
    SecureForm.method = 'POST';
    SecureForm.target = '_self';
    var shopLogin = document.createElement('input');
    shopLogin.type = 'hidden';
    shopLogin.name = 'a';
    shopLogin.value = this.shopLogin;
    SecureForm.appendChild(shopLogin);
    var VBVRisp = document.createElement('input');
    VBVRisp.type = 'hidden';
    VBVRisp.name = 'b';
    VBVRisp.value = this.VBVRisp;
    SecureForm.appendChild(VBVRisp);
    var urlCallBack = `${this.eeDomain}/edison/services/casa/epaymentCDC-AC`;
    var pathName = '?urlRedirect=' + encodeURI(`${this.acDomain}/`);
    var callBack = document.createElement('input');
    callBack.type = 'hidden';
    callBack.name = 'c';
    callBack.value = urlCallBack + pathName;
    SecureForm.appendChild(callBack);
    document.body.appendChild(SecureForm);
    SecureForm.submit();
  }

  onGetOutcomeSuccess(response, successToken) {
    const METODO_CDC = 'L';
    console.log('onGetOutcomeSuccess');
    this.loader = false;
    console.log('Invio dati cdc: ', successToken, response);
    if (response.fail) {
      this.erroreCarta = true;
    } else {
      this.networkService.invioDatiCdc(successToken, response).subscribe(
        (data) => {
          this.step = 3;
          this.success = true;
          this.error = false;
          window.localStorage.removeItem('token');
        },
        (error) => {
          window.localStorage.removeItem('token');
          this.step = 3;
          this.success = false;
          this.error = true;
        }
      );
    }

    console.log(response);
  }

  submitCdcForm(event: any) {
    this.cdcFormData = event;
    this.addebitoDirettoCdcConferma();
  }

  async verifyPathPares() {
    this.loader = true;
    //Nel caso la querystring contenga il PaRes (3Dsecure check effettuato)
    console.log('la variabile è ', window.location.href.indexOf('PaRes=') > -1 &&
      localStorage.getItem('cdcTransKey') != null);
    if (
      window.location.href.indexOf('PaRes=') > -1 &&
      localStorage.getItem('cdcTransKey') != null
    ) {
      let serviceToLog = 'createPaymentPage';
      try {
        this.TransKey = localStorage.getItem('cdcTransKey').toString();
        let pares = this.getParameterByName('PaRes');
        this.PaRes = pares.replace(/\s/g, '+');
        this.shopLogin = localStorage.getItem('cdcShopLogin').toString();
        this.token = localStorage.getItem('cdcToken').toString();
        const data = await this.networkService.createPaymentPage(
          this.shopLogin,
          this.token
        );
        this.loggerService.postLogInfo('createPaymentPage - RESPONSE', data);
        serviceToLog = 'sendPayment';
        const resp: any = await this.networkService.sendPayment3DSecure(
          this.PaRes,
          this.TransKey
        );
        this.loggerService.postLogInfo('sendPayment - RESPONSE', resp);
        const successToken = resp.EncryptedString;
        serviceToLog = 'getOutcome';
        const resOutcome = await this.networkService
          .getOutcome(successToken, this.shopLogin)
          .toPromise();
        this.loggerService.postLogInfo('getOutcome - RESPONSE', resOutcome);
        await this.onGetOutcomeSuccess(resOutcome, successToken);
      } catch (e) {
        this.loggerService.postLogInfo(`${serviceToLog} - ERROR`, e);
        console.log(e);
        this.step = 3;
        this.success = false;
        this.error = true;
      } finally {
        localStorage.removeItem('cdcTransKey');
        localStorage.removeItem('cdcShopLogin');
        localStorage.removeItem('cdcToken');
        this.loader = false;
      }
    } else {
      this.step = 3;
      this.success = false;
      this.error = true;
      this.loader = false;
    }
  }

  getParameterByName(name, url?) {
    if (!url) {
      url = parent.window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results || !results[2]) return '';
    return decodeURIComponent(results[2]);
  }

  closeModal() {
    this.erroreCarta = false;
  }
}
