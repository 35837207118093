import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { environment } from 'src/environments/environment';

declare let GestPay: any;
@Injectable({
  providedIn: 'root',
})
export class NetworkServiceService {
  baseUrl = environment.acDomain;
  TOKEN_URL = '/api-b2c/pagamenti/v1/getTokenRicorrenteAssistenzaCasa';
  GET_OUTCOME_URL = '/api-b2c/pagamenti/v1/getOutcomeAssistenzaCasa';
  SUCCESS_CODE = 0;
  SERVICE_ID = 'getOutcome';

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService
    ) {}

  getRequest(url: string, options: any) {
    options.headers.accept = 'application/json'
    return this.http.get(url, options);
  }

  postRequest(url: string, body: any, options?: any) {
    if(options){
      options.headers.accept = 'application/json'
    } else {
      options = {
        headers: {
          accept: 'application/json'
        }
      }
    }
    return this.http.post(url, body, options);
  }

  recuperoDatiCdCAltriCanali(token: any){
    return this.getRequest( this.baseUrl + "/api-b2c/pagamenti/v1/recuperoDatiCdCAltriCanaliAssistenzaCasa", {headers: {token: token}} )
  }

  getToken(codiceCliente: any, contractAccount?: any, segmento? : string, shopLogin?: string) {
    const params: any = [];
    const param1 = { chiave: 'codiceCliente', valore: codiceCliente || '' };
    const param2 = { chiave: 'contractAccount', valore: contractAccount || '' };

    params.push(param1);
    params.push(param2);

    const listaParam = {
      listaParam: params,
      segmento: segmento
    };
    this.loggerService.postLogInfo('getTokenRicorrente - CHIAMATA A SERVIZIO', listaParam);
    return this.postRequest(this.baseUrl + this.TOKEN_URL + '?shopLogin=' + shopLogin, listaParam);
  }

  createPaymentPage(shopLogin: any, token: any) {
    this.loggerService.postLogInfo('createPaymentPage - CHIAMATA A SERVIZIO', shopLogin, token);
    return new Promise((resolve, reject) => {
      GestPay.CreatePaymentPage(shopLogin, token, (response: any) => {
        const CREATE_PAYMENT_SUCCESS_CODE = 10;
        console.log('CreatePaymentPage response');
        console.log(response);
        const errorCode = parseInt(response['ErrorCode']);
        if (errorCode == CREATE_PAYMENT_SUCCESS_CODE) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }

  sendPayment(cdcObj: any) {
    this.loggerService.postLogInfo('sendPayment - CHIAMATA A SERVIZIO');
    return new Promise((resolve, reject) => {
      GestPay.SendPayment(cdcObj, (response: any) => {
        const errorCode = parseInt(response['ErrorCode']);
        const CREATE_PAYMENT_SUCCESS_CODE = 10;
        const CREATE_PAYMENT_SUCCESS_3DS_CODE = 8006;
        const SEND_PAYMENT_SUCCESS_CODE = 0;
        if (errorCode == SEND_PAYMENT_SUCCESS_CODE || errorCode == CREATE_PAYMENT_SUCCESS_CODE) {
          resolve(response);
        } else if (errorCode == CREATE_PAYMENT_SUCCESS_3DS_CODE) {
          response['threeDS'] = true;
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }

  getOutcome(tokenEsito: any, shopLogin: string) {
    // let shopLogin: string = environment.shopLogin.oneshot;
    let data: any = {
      tokenEsito: tokenEsito,
      shopLogin: shopLogin,
    };
    this.loggerService.postLogInfo('getOutcome - CHIAMATA A SERVIZIO', data);
    return this.postRequest(this.baseUrl + this.GET_OUTCOME_URL, data).pipe(
      map((response: any) => {
        if (response._header && response._header.stato == this.SUCCESS_CODE) {
          if (response.errorCode == this.SUCCESS_CODE) {
          } else {
            const fail = this.__errorHandler(response, 'AUTORIZZAZIONE_NEGATA');
            data.fail = fail;
          }
        } else {
          const fail = this.__errorHandler(response, 'GENERICO_VALIDAZIONE_CARTA');
          data.fail = fail;
        }
        return data;
      })
    );
  }

  invioDatiCdc(successToken, response){
    let data = {
      tokenEsito: successToken,
      shopLogin: response.shopLogin
    }
    const token = window.localStorage.getItem('token');
    let options = {
      headers: {
        token: token
      }
    }
    return this.postRequest(this.baseUrl + "/api-b2c/pagamenti/v1/invioDatiCdCAltriCanaliAssistenzaCasa", data, options)
  }

  sendPayment3DSecure(pares: string, transKey: string) {
    this.loggerService.postLogInfo('sendPayment - CHIAMATA A SERVIZIO', { PARes: pares, TransKey: transKey });
    return new Promise((resolve, reject) => {
      const obj: any = {};
      obj.PARes = pares;
      obj.TransKey = transKey;
      GestPay.SendPayment(obj, (response) => {
        if (response.ErrorCode == "0") {
          // Transaction correctly processed ! 
          // Decrypt the Result.EncryptedString property to read the 
          // transaction result
          resolve(response);
        } else {
          // An error has occurred, check ErrorCode and ErrorDescription
          // properties of the Result object
          console.log("---reject---", response)
          reject(response);
        }
      });
    });
  }

  __errorHandler(response, customError) {
    response.__errorObj = {
      module: this.SERVICE_ID,
    };

    if (customError) {
      response.__errorObj.errorCode = customError;
    } else {
      const errorCode = parseInt(response.ErrorCode);
      response.__errorObj.errorCode = 'GENERICO_VALIDAZIONE_CARTA';
    }
    return response;
  }
}

// token=ZXlKamRIa2lPaUpLVjFRaUxDSmxibU1pT2lKQk1USTRSME5OSWl3aVlXeG5Jam9pWkdseUluMC4uN1lKV2haaUFaOFZQSnByMi4wcDFjZDl3MHN6ZUVJYXA1MnRwTWloeUlWNmpFbG1QTFNQQ1B6NmhLLWxPeUMxNHRtZVAzWUM5RVI0ZzkxS1FrNzBrNm04VWdib2ZmWmxtYVIyWFY4dkFTWU56Mmhuc3FYTWJCdTJ0Qml2UTM5NWZHUjVwS2N3dmxHOFl1ajNjNklVVHRfaW9EMy1QM1dxTF8yR2lXVzZ2a0lIV0lEN3BUQmlUSTJsMHl2eEttR1lMUjU3YUJjTm1SM1g2cXQ0dzhxSmxRWEd0UmlJV0tSZjNWYU5iOHFHYXRQenZ1VERKUk1tRmZYeTlPWmFhUFJiVnRiTVFjbmlONGQzQlNJTGNtMlpwdXg0dzFMWWlyTWlIS3FhZEo3ZUJLalFVUnNhUWhKS1pYRk1XU0Uyak1DUmJNdUx1SU5ieEZ1MFpyTHJzTDJUcVYyb2ptOHlrblFMSDdDTldlNWZCZW5RcmtrU3Z0T1NFVDJIampRVkZfS2lIRmFHTmN6cldBN0JWeE1OUE5CWU9USGxHaHJsZGxtaVJaaUg4M0Vlb2ZVckV5WXl4UTkxaVFnbWhHYmhwTEpWQVhrZy5mRlpMRDQxeUJCcW9RaG8tZ05vbnVn

// token=ZXlKamRIa2lPaUpLVjFRaUxDSmxibU1pT2lKQk1USTRSME5OSWl3aVlXeG5Jam9pWkdseUluMC4uYXlNRjdqS0tsT1J1SDNGZS5kR3k3YTZhdlB3djcxVUVUS05aX01FUEF2c201ZGpmckpwakhreGNlZTR1ZFJ1eGN0SUdYYVlXU3RPLWNQVmFXeXJ5Q0UzOG5kSDFkalhfTGJHekltdjFwVFlMaEJHaDNyTUk0MjVfelhxdmh4S0FBa05RVmgzalhGNEE1UXlWNjBZN0dqSGdZVk9paEx3YnY1NE8tMDhoemNEWnN5eXl1Z0ZSZlBJSklETzdvYUw2Tkk0R1ZXNExHN19yVkZkd1htZzdXZUwzT2FaWW5qZjR2dkJxdnlud1pEVFQ4amFyVDRaeGM4c3BuLXpSU2dEamVfOXlCN0lPWVhodTQ3TWtmMEF0M29XTU9hWkt5ME56U29mbnJ2WFlMUV80WjhQOGM1VVlvaURnWHFYWWEwTGo3amVnNDBvaDVGUTJGdnY3Q2FYN1R0YjhDMlRiRkZVbklSOUpIUFpRU1RVZjc3YV9YY21sa3puRjJ0N2JaUDZXU0JySHo0b3BJLXBxTG0zTmdzaHhFXzhRd1NocExoR3F1aUJOakNfVWtpZE9lVksyanhNcm5DZTgxQVR4VnpfV0Zqdy5NVWxGYVY4X3BXM01qQXFwVUJ2YkFn
 
// token=ZXlKamRIa2lPaUpLVjFRaUxDSmxibU1pT2lKQk1USTRSME5OSWl3aVlXeG5Jam9pWkdseUluMC4uVnBtZmVxNnJYTDZxZHFOVS5FOXJUdWc1OEVRYWczeXhwRUp0OVVyY1dDVW9DX3g5UE5zR3Q5NVBmMTFDM2hJalFQLWpaUm1kQTEyNFhqZkR5czFCbXhQUjhoRWdIYmVPS3k3b0VGbEtGNXZJYlo3ODJuVXROMTFNbEhpbzhnbTJBdUxlWmE5U1l0M215TWdoOGQ1UXdmd3NzWXNTZ1E5ZDl2VWdtSjB5b3pPSWhOR191VkpNZ09aREtvOUFZZlVrUlhmTTdWd2VsZGpBclJtaTgwVkIzRjVldEt4d2hDZ2FqVncxamgyeGFmWlJUQVJtUDhsV1BlakxjaEtnSlB6dG5sUVloMFBocmVEZ29IWkMxODNfWmNwYTRqdW5GRGp1TkpxaXBETkJGUWJiQk5yZVZEOFFwYTJxbjNnRVphbFdvTVd3YXZTVEpoWldRVjhCS0N3STREekl3cTkwanc0QUFWWXlOSFp0NlV4bklQZnlCNUc3dERaendFZ2U3eURyNDlnN3FBX1FzSzlwUXpyWFlnUnloSGJ6RHZiblo0LU1uYl9IaWlmSWxkejFfbURpUndJVjFiT3VtMlFyek5XYlNxUS5IR3JCalZiVlloc0taQlRXeHAzVkFn
 
// token=ZXlKamRIa2lPaUpLVjFRaUxDSmxibU1pT2lKQk1USTRSME5OSWl3aVlXeG5Jam9pWkdseUluMC4ubF9yRGotdGYzOTdzQVA5NC5ZbkRjME8xMDJ1clBFTDFMQ0NZU25TazdTRFh6ako1bVdjb0FwYmxoU3NFeTdVUHd2Um5ybklpR0ctbGdfdEQzLTlTV1djR1ZxRVpjMVhXNnA1bnV4aG41M0phSFZZZGUyMzBFY1FNc3BaSm1CeVJzNDg3NzhzMTJLN19sdGhxNTc0bGdjV2JxV3BVNzZHWG15WUdiVDI1WXpPMTVjaGxOdFl0SHJUYTNYQ2hZVHJVRDhiUV90UFVRclh3Q2M3aHBjNVRRUkdkaTFlVjRSeVhKbnFfNVd1ZXBBMmx3czh6ZWpENUc5ODE2bXNmODllXzRHUDFFWEgzcFp3M0FiSU40bDlVTkNKTmR1YVljcEJzdzI4U05najhmVXkza29uYjdoTkRuMThkQjQ2SnpuX0ZZcWc2Q2tIeFRUQjJ6U2pEQTQ0cWV6NlJ3bUFmOEwzMlhTLS1rVW9ibno3d1FnSndrb0xRaV9SLWswc1ducG1LbmphSGZtYmM2T3hXd1I0Z0RMWE1PekRVbEcyTGoxU3lUdDMyRXhncXBDbFUtYWxiUUtzbzVFdmVadjRtRmZGY0JGdy5OdGNHQTg1QWdXMEVxczdROG5TcU53

 
// ZXlKamRIa2lPaUpLVjFRaUxDSmxibU1pT2lKQk1USTRSME5OSWl3aVlXeG5Jam9pWkdseUluMC4uYW45LUVtWjdQcTdaeGpFLS5wY3o1M0xlb1lkSnFpY05NZS1wMVM0a251dzRjRDRvQWRsVXB3YlFGWUJCT05RM0J4VUNGRjJxQW40T2diTGRFWXYxcmdadlVzc3VoSTRUN2RtQ1N2YzZ3MV9EejJMaFlZajI3QW9DWWh6OEVEbjM1X1UyQ1BpaUc5WWZTNTJHdkg0SVVEVzNDcFd2RGlqcGRad2NQNEZJQ2ZQbk5NNnZ0N2d2OTk2VzZyYVQ3UXE3V2hSYlJQLUtqSWhtYTgyVU9EM2pXRHVIZVNjOWtnNU5TYkdYSnZRYVFZdGZua0lETzFxSmhBRmJESDVqQWFzcmFwZVp3VmN0OW93bHNna2d6WFQyUmp6NXpNMU5OOEZSejByeHhYVXU5cUtJU3c5U0s5OUlxVnpmZVJ2aUN3S05XZjR4cmhGRFZNUHBSZmdYcFlENTZDQU9LV0xrWWdCZU1SMmdDUldLcEVydExFOXdIV0EzRzhwektHVmw4bHFDRDFad0w4ckVvR1JUajB6NEpwRi1uOVR3dmJ4OFd3TGg4NkxSVldfUnN3R0dBNFlEQTc4aU8yNnpvbklhUEdKcVd6Zy5jMlhUS3hRdmZ1ZjhUNDJFcFNfMTdR
 
// ZXlKamRIa2lPaUpLVjFRaUxDSmxibU1pT2lKQk1USTRSME5OSWl3aVlXeG5Jam9pWkdseUluMC4uYmxUWnZYRm5iMXpFQy1KdS5aMVNYQ3V0MFUxZncxUHNPa3gxYWpmR0F0ZXlIVGlaZHBOQzUtLXdQUWJJRlZ5cUpqTnhvQXlUSnFxZUJ0SVN5R2F4aFFDMm5EUG1KdWNBR19KNlg1R0YtQVgwc1l1ZkVPanRWZzRvdnRIY2c2VG5oSl9VYmp6Yl9rOFVoZkNQM0VQZm05T2RabE5Xekh0VjFTNm1sLTVVNkx4RVpTRUZmZG1NM0kxUFNTYjN4WmRCRjlQRkR2V0lBQk9ObU12QmtQUFdXNFdDR2NwNnJPY0lqWkxuTWRwTE9UWU4yQnQtdlJOaHpUWnN3NzYyMkthbmRobXVEcnpRLThEa21fUG55NVZVRjBMWE9JcUFDbVp6WHlMTHQ0QlJZN09zLU1od3Z6amxWWXJDeFFCek1FMURWa3B5WndRUGhCai1HWW52YUVTXzI0cFJjemlUWjhCZ3lxcUdsbm1hNFNOMk4xWTItazdVM2JZTkNOdzdKTlQ2ak1vZ1QtaUhHNHR0S0h4SVFfQ0VDa1RsQ3NBcGFiUWtHcXQ4SVpnZzhoc0FZck5DZlRrZ3dxT0Y3a044WFZnRUpVQS5pdkhqWHpIRng1T2lPaGRUcnYtVzN3                                                                                                                                                                                                                                               
 
// ZXlKamRIa2lPaUpLVjFRaUxDSmxibU1pT2lKQk1USTRSME5OSWl3aVlXeG5Jam9pWkdseUluMC4uMlBCTlpmUlFvN0NDZ2xwRi5heU5nSlZYTlE0b21lWXNzNXZ4WC1wZUJnU1RfdUR6M0Fnd1FDYlQzUDBNcFZuTVhydWZHaXd5UGxvckJwS2JlTkVfdnZwMWcwZndram1MeUtPVmhVZV9feDhkaG83MVFueDdQZU01NUc1UXBQRTItdTlDaFU3clN3RVlmc3pqTzBUMXJfbzBTcURnbDFGZnFGS1dHTGpuNlRBRnU2aVNPZkRCT1ZSbXJJQWs5a05LS0NMckRJR1NZS0pORjl6NlNtU3p5RHdaYU1NR3h6ZC1DT0ptSUhDS0phZWlIZ01WQS1nTHFpZDlRUy12d3NuandxUDRpaG0ya3lZbVdfQUhEeVZHbTRJLWszcC0tTXVnbHFaMmZjWHBFWHFzbFNIQzJlcDJIWkZ1b2gzUUZtS3NyWmxoWmJEZkoxdmV6bzRDeC0zOWgyN1QzR3VRTE5aTUNBaTB6bno4MEVseENNZGw1TU9sZGtmdUpXbEc4eUJ1V1B0bDBVUGl5WFB4cUhaNW83anlZNS1VazBXQW1RSExXRENtQXZjUzBScnFia25BNVJkMllsMVNpV2xXOFpyZmtNQS44MHlPb21IZjNjbUNpUElXQ21hV3dB                                                                                                                                                                                                                                               
 
