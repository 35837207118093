import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegexService {
  regExNumeri = '[\\d]*';
  regExNome = "[a-zA-z' ]*";
  regExNumeroFisso = '^[0]\\d{5,24}$';
  regExNumeroCell = '^[3]\\d{8,10}$';
  regExIBAN = '^[Ii][Tt]\\d{2}[a-zA-Z]\\d{10}[0-9a-zA-z]{12}$';
  regExEmail = "^[A-Za-z0-9]+(\\.?[_\\-\\'A-Za-z0-9]+)*@[A-Za-z0-9]{1}(\\.?[\\-\\'A-Za-z0-9]+){1,62}(\\.[A-Za-z]{2,5})$";
  regEx_Estero= '^(?:(?:SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$'
  // var regExUsername = "^[A-Za-z0-9\\(\\)\\[\\]\\@\\.\\_\\-\\+\\$]*$";
  // var regExUsername = "^(\w|_|-|\.|@)+$";
  // var regExUsername = "^[\\w_.@\-]*$"; //Non più valida per email zingaro 27/03
  regExUsername = '^[\\w_.@][\\w_.@-]*$';
  regExPassword = '^[A-Za-z0-9[\\]\\!\\@\\#\\^\\(\\)\\+\\=\\è\\é\\à\\ù\\ò\\_\\-\\*\\$]*$';
  regExPasswordAllowedSpecialCharacters = {
    id: 0,
    regEx: new RegExp('^[A-Za-z0-9[\\]\\!\\@\\#\\^\\(\\)\\+\\=\\è\\é\\à\\ù\\ò\\_\\-\\*\\$]*$'), // Validazione caratteri speciali
    errorMsg: 'Sono consentiti solo i caratteri speciali [  ]  !  @  #  ^  (  )  +  =  è  é  à  ù  ò  _  -  *  $',
  };
  regExPasswordEmpty = {
    id: 1,
    regEx: new RegExp('[^s*$]'), // Controllo if empty string
    errorMsg: 'Campo obbligatorio',
  };
  regExPasswordUpperCase = {
    id: 2,
    regEx: new RegExp('[A-Z]'), // Controllo maiuscola
    errorMsg: 'Inserisci almeno un carattere maiuscolo',
  };
  regExPasswordLowercase = {
    id: 3,
    regEx: new RegExp('[a-z]'), // Controllo minuscola
    errorMsg: 'Inserisci almeno un carattere minuscolo',
  };
  regExPasswordNumericCharacter = {
    id: 4,
    regEx: new RegExp('[0-9]'), // Controllo carattere numerico
    errorMsg: 'Inserisci almeno un carattere numerico',
  };
  regExPasswordSpecialCharacters = {
    id: 5,
    regEx: new RegExp('[\\[\\]\\!\\@\\#\\^\\(\\)\\+\\=\\è\\é\\à\\ù\\ò\\_\\-\\*\\$]'), // Controllo carattere speciale
    errorMsg: 'Inserisci almeno un carattere speciale',
  };
  // RegExs used to calculate password validity
  regExsPassword = {
    regExPasswordEmpty: this.regExPasswordEmpty,
    regExPasswordAllowedSpecialCharacters: this.regExPasswordAllowedSpecialCharacters,
    regExPasswordUpperCase: this.regExPasswordUpperCase,
    regExPasswordLowercase: this.regExPasswordLowercase,
    regExPasswordNumericCharacter: this.regExPasswordNumericCharacter,
    regExPasswordSpecialCharacters: this.regExPasswordSpecialCharacters,
  };
  // RegExs used to calculate password thermometer
  regExsPasswordThermometer = {
    regExPasswordEmpty: this.regExPasswordEmpty,
    regExPasswordUpperCase: this.regExPasswordUpperCase,
    regExPasswordLowercase: this.regExPasswordLowercase,
    regExPasswordNumericCharacter: this.regExPasswordNumericCharacter,
    regExPasswordSpecialCharacters: this.regExPasswordSpecialCharacters,
  };

  // Define the error message to be displayed to alert user about maximum characters occurring
  maximumCharactersOccurringPassword = {
    id: 5,
    errorMsg: 'La password non può contenere caratteri ripetuti più di 4 volte',
  };

  passwordRegExsErrors = [];
  regExCap = '^([0-9]{1,5})$';
  regExRagioneSociale = '^[^\u00C0-\u017F]*[^\u00C0-\u017F^\u0020]+[^\u00C0-\u017F]*$';
  regExNickname = "^[0-9a-zA-Z\\_\\.\\'\\^\\ ]+$";
  regExCc = '^[ ]*[0-9]{13,19}[ ]*$';
  regExExpmm = '^([1-9]|1[0-2])$';
  regExPdrGas = '^\\d{1,11}$';

  validateCF(cf: string) {
    if (!cf || cf === '') return false;
    var validi, i, s, set1, set2, setpari, setdisp;
    cf = cf.toUpperCase();
    if (cf === '') {
      return true;
    }
    if (cf.length != 16) {
      return false;
    }
    validi = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (i = 0; i < 16; i++) {
      if (validi.indexOf(cf.charAt(i)) == -1) return false;
    }
    set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ';
    setpari = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    setdisp = 'BAKPLCQDREVOSFTGUHMINJWZYX';
    s = 0;
    for (i = 1; i <= 13; i += 2) s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    for (i = 0; i <= 14; i += 2) s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    if (s % 26 != cf.charCodeAt(15) - 'A'.charCodeAt(0)) {
      return false;
    }
    return true;
  }
  validatePIVA(pi: string) {
    if (pi === '') {
      return true;
    }
    if (pi.length != 11) {
      return false;
    }
    const validi = '0123456789';
    for (let i = 0; i < 11; i++) {
      if (validi.indexOf(pi.charAt(i)) == -1) {
        return false;
      }
    }
    let s = 0;
    for (let i = 0; i <= 9; i += 2) s += pi.charCodeAt(i) - '0'.charCodeAt(0);
    for (let i = 1; i <= 9; i += 2) {
      let c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0));
      if (c > 9) c = c - 9;
      s += c;
    }
    if ((10 - (s % 10)) % 10 != pi.charCodeAt(10) - '0'.charCodeAt(0)) {
      return false;
    }
    return true;
  }

  validateNameSurnameInCF(nome: string, cognome: string, cf: string) {
    if (!nome || !cognome || !cf) {
      return true;
    }
    if (cf.length <= 11) {
      return true;
    }

    nome = nome.toUpperCase().replace(/[^A-Z]+/g, '');
    cognome = cognome.toUpperCase().replace(/[^A-Z]+/g, '');
    cf = cf.toUpperCase();
    var i;

    nome = this.pickNameConsonants(nome) + this.pickVocals(nome);
    for (i = nome.length; i < 3; i++) {
      nome = nome.concat('X');
    }
    cognome = this.pickConsonants(cognome) + this.pickVocals(cognome);
    for (i = cognome.length; i < 3; i++) {
      cognome = cognome.concat('X');
    }

    if (nome.length > 0 && cognome.length > 0 && cf.length > 0 && cognome.substr(0, 3) + nome.substr(0, 3) != cf.substr(0, 6)) {
      return false;
    } else {
      return true;
    }
  }
  pickVocals(nome: string) {
    return nome.replace(/[^AEIOU]+/g, '');
  }

  pickConsonants(nome: string) {
    return nome.replace(/[AEIOU]+/g, '');
  }

  pickNameConsonants(nome: string) {
    var result = nome.replace(/[AEIOU]+/g, '');
    if (result.length > 3) return result[0] + result[2] + result[3];
    else return result;
  }
  
}
