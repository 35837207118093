import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss']
})
export class WaitComponent implements OnInit {

  @Input() error = false;
  @Input() success = false;
  subtitle = 'La preghiamo di attendere il processo di pagamento.'
  constructor() { }

  ngOnInit(): void {
  }

}
