import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-errore-carta',
  templateUrl: './errore-carta.component.html',
  styleUrls: ['./errore-carta.component.scss']
})
export class ErroreCartaComponent implements OnInit {
  @Output() closeModal = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }

  close(){
    this.closeModal.emit();
  }
}
