import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private readonly OPTIONS: { headers: HttpHeaders; } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private readonly URL = `${environment.eeDomain}/edison/services/logger/logInfo`;

  constructor(
    private http: HttpClient,
  ) { }

  postLogInfo(...args) {
    const stringToLog = this.getStringToLog(...args);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    console.debug(stringToLog);
    return this.http.post(this.URL, stringToLog, options).toPromise();
  }

  private getStringToLog(...args) {
    let objectToLog = {};
    let index = 1;
    let siteType = `ASSISTENZA CASA - ${navigator.platform}`;
    const isPostPaymentPage =
      args[0].includes('THANK-YOU-PAGE') || args[0].includes('ERROR-PAGE')
        ? true
        : false;
    objectToLog[
      `${isPostPaymentPage ? "L'UTENTE E' ATTERRATO IN" : 'SERVICE'}`
    ] = args.shift();
    args.forEach((element) => (objectToLog[`arg${index++}`] = element));
    objectToLog[`PLATFORM`] = `SITO=${siteType}`;
    objectToLog[`BP`] = `${window.localStorage.getItem('codiceCliente')}`;
    if (window.localStorage.getItem('PAYMENT_TYPE')) {
      objectToLog[`PAYMENT TYPE`] = `${window.localStorage.getItem(
        'PAYMENT_TYPE'
      )}`;
      if (isPostPaymentPage || objectToLog['SERVICE'].includes('ERROR'))
        window.localStorage.removeItem('PAYMENT_TYPE');
    }
    if (window.localStorage.getItem('CANCELED_BY_USER')) {
      objectToLog[`CANCELED BY USER`] = `true`;
      window.localStorage.removeItem('CANCELED_BY_USER');
    }
    return JSON.stringify(objectToLog);
  }

  logInfo(flusso: string, content: any): Observable<any> {
    let body: string = '{';
    body += `"FLUSSO"="${flusso?.toUpperCase()}",`;
    body += `"SITO"="${`ASSISTENZA CASA - ${navigator.platform}`
      }",`;
    body += `"BP"="${window.localStorage.getItem('codiceCliente')}",`;

    body += `${JSON.stringify(content).slice(1)}`;
    return this.http.post(this.URL, body, this.OPTIONS);
  }
}
